import React from "react";
import "./BreadCrumbs.css";

const BreadCrumbs = ({ title, description }) => {
    return (
        <div className="BreadCrumbs">
            <h2>{title}</h2>
            <span className="separator">|</span>
            <p>{description}</p>
        </div>
    );
};

export default BreadCrumbs;
