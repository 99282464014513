import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));

// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));

const loggedIn = window.localStorage.getItem('isLoggedIn');

const routes = [

  {
    element: <AuthGuard><MatxLayout /></AuthGuard>,
    children: [
      ...materialRoutes,
      {
        path: '/dashboard',
        element: <Analytics />,
        auth: authRoles.admin
      },
      // Add more protected routes here
    ]
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <JwtLogin /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/', element: <Navigate to={loggedIn ? "/dashboard" : '/session/signin'} replace /> },
  { path: '*', element: <Navigate to="/session/signin" replace /> } // Catch-all route for undefined paths
];


export default routes;

