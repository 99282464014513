import { CircularProgress, Box, styled } from '@mui/material';
import Digiesim from '../../assets/Digiesim-favicon-10.png';

const StyledLoading = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    width: 'auto',
    height: '25px'
  },
  '& .circleProgress': {
    position: 'absolute',
    left: -7,
    right: 0,
    top: 'calc(50% - 25px)'
  }
});

const Loading = () => {
  return (
    <StyledLoading style={{
      backgroundColor: '#edf6f9'
    }} >
      <Box position="relative"
        style={{
          height: '100px',
          width: '120px'
        }}
      >
        {/* <img src={Digiesim}
          style={{ height: '100%', animationName: "Loader", animationDuration: '2s', animationIterationCount: 'infinite', transition: '.5s ease all' }} /> */}

        <img
          src={Digiesim}
          style={{
            height: '100%',
            animation: "Loader 2s infinite",
            transition: '0.5s ease all',
          }}
        />
      </Box>
    </StyledLoading>
  );
};
// 
export default Loading;
