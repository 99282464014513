import { Button } from "@mui/material";
import React from "react";
import { H2, H1, H3, H4, H5 } from "app/components/Typography";
import { color } from "echarts";

const ErrorPage = () => {
  const handleClick = () => {
    window.location.reload();
  };

  return (
    <>
      <div style={{
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#F9F5F1'
      }} >
        <br />

        <H1 style={{ color: '#8124E2' }} >
          Something went wrong !!  Try clicking the Reload Button .
        </H1>
        <Button
          onClick={handleClick}
          style={{ color: "green", margin: "10px" }}
          variant="outlined"
          color="inherit"
        >
          Reload
        </Button>
      </div>

    </>
  );
};

export default ErrorPage;
