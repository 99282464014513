import { lazy } from 'react';
import Loadable from 'app/components/Loadable';
import { element } from 'prop-types';
import Maintainance from '../Maintainance/Maintainance';
// import AllCustomers from '../AdminPages/AllCustomers/AllCustomers';

const AllCustomers = Loadable(lazy(() => import('../AdminPages/AllCustomers/AllCustomers')));
const ESIMs = Loadable(lazy(()=> import('../AdminPages/ESIMS/ESims')));
const UserDetails = Loadable(lazy(() => import('../AdminPages/AllCustomers/UserDetails')));
const EsimDetails = Loadable(lazy(()=> import('../AdminPages/ESIMS/ESimDetails')));
const PlanType = Loadable(lazy(() => import('../AdminPages/DataPlans/PlanType')))
const UserSetting = Loadable(lazy(() => import('../AdminPages/UserSetting/UserSetting')))




const materialRoutes = [

    { path: '/all_customers', element: < AllCustomers /> },
    { path: '/eSims', element: <ESIMs /> },
    { path: '/eSimDetails', element: <EsimDetails />},
    { path: '/maintenance_page', element: < Maintainance /> },
    { path: '/user_details', element: <UserDetails /> },
    { path: '/plan_types', element: <PlanType />},
    { path: '/user_setting', element: <UserSetting />}
];

export default materialRoutes;
