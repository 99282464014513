import React from 'react'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
import './Maintainance.css'
import { Box, Button, FormControl, styled, Switch } from '@mui/material';
import { useState } from 'react';
import { H3 } from 'app/components/Typography';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#b2efb3",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Maintainance = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const customId = "forNotShowingMultipleToast";
  const DomainUUID = localStorage.getItem("DomainUUID")

  const [MaintainanceModeButton, setMaintainanceModeButton] = useState(false);
  const [MaintainanceText, setMaintainanceText] = useState("");

  const HandleToggleButton = () => {
    setMaintainanceText("")
    setMaintainanceModeButton(!MaintainanceModeButton);
  }

  const HandleMaintenanceMode = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "state": MaintainanceModeButton.toString(),
      "message": MaintainanceText
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${REACT_APP_BASE_URL}/enable_disable_maintenance`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          toast.success(result.Msg, {
            toastId: customId,
            position: 'top-center',
            autoClose: 3000,
          });
        } else {
          toast.error(result.Msg, {
            toastId: customId,
            position: 'top-center',
            autoClose: 3000,
          });
        }
      })
  }

  const FetchMaintenanceStatus = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "domain_uuid": DomainUUID,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${REACT_APP_BASE_URL}/get_admin`, requestOptions)
      .then((response) => response.json())
      .then((result) => {

        if (result.accounts.maintenance == "true") {
          setMaintainanceModeButton(true)
        } else {
          setMaintainanceModeButton(false)
        }
        setMaintainanceText(result.accounts.maintenance_msg)
      })
  }

  useEffect(() => {
    FetchMaintenanceStatus()
  }, [])


  return (
    <>
      <BreadCrumbs title="Maintenance" description="Maintenance Mode" />

      <div className="Maintainance">
        <h2 id='Information' >
          This is the maintenance mode page where you can activate the maintenance mode whenever necessary. When enabled, the website will be temporarily unavailable to users. You also have the option to enter a custom message that will be displayed to inform visitors about the ongoing maintenance and provide any additional details or expected downtime.
        </h2>

        <hr
          style={{
            border: "1px lightblue solid",
            width: "80%",
            margin: "20px auto",
          }}
        />

        <div className="Box_1">
          <div id="ToggleButton" style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <FormControl component="fieldset" style={{ margin: "0px 10px" }}>
              <IOSSwitch
                label="Status"
                checked={MaintainanceModeButton}
                onChange={HandleToggleButton} />
            </FormControl>
            <p style={{ fontSize: "16px" }}>Enable the Maintenance mode</p>
          </div>

          {
            MaintainanceModeButton &&
            <div id='Text'>
              <H3>Maintenance Mode Text </H3>

              <textarea
                value={MaintainanceText}
                onChange={(e) => {
                  setMaintainanceText(e.target.value);
                }}
                placeholder="Write your text here..."
              />

              <p style={{ fontWeight: "100" }}>
                The text that will be visible to your customers when accessing during maintenance mode
              </p>
            </div>
          }
        </div>

        <Button
          style={{
            width: "70%",
            fontSize: "17px",
            color: "white",
            backgroundColor: "#5CA7E0",
            borderRadius: "10px",
            margin: '10px auto ',
          }}
          onClick={HandleMaintenanceMode}
        >
          Update
        </Button>
      </div >
    </>
  )
}

export default Maintainance
